import React from "react";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Heading,
  Container,
  Center,
  UnorderedList,
  ListItem,
    Box
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { Markup } from "interweave";

import Layout from "../components/Layout";

const Archives = (props) => {
  const { pageContext } = props;
  const { year, month, volumes } = pageContext;

  const breadcrumbs = [
    <BreadcrumbItem key="breadcrumb-all">
      <BreadcrumbLink href="/archives/">All Volumes</BreadcrumbLink>
    </BreadcrumbItem>,
  ];

  if (year) {
    breadcrumbs.push(
      <BreadcrumbItem key={`breadcrumb-${year}`}>
        <BreadcrumbLink href={`/archives/${year}`}>
          Volume {year}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  }

  if (month) {
    breadcrumbs.push(
      <BreadcrumbItem key={`breadcrumb-${month}`}>
        <BreadcrumbLink href={`/archives/${year}/${month}`}>
          Issue {month}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  }

  const issues = volumes[year];

  return (
    <Layout>
        <Container maxW='2xl' paddingTop="50px">
          <Heading>microPublication Table of Contents</Heading>
          {breadcrumbs && (
            <Breadcrumb>{breadcrumbs.map((bc) => bc)}</Breadcrumb>
          )}

          {month && (
            <>
              {Object.entries(issues[month]).map(([index, article]) => {
                const { authors, doi, title } = article
                const articleId = doi
                  .split("/")
                  .pop()
                  .replace(/\./g, "-")
                  .toLowerCase();

                const lastAuthor = authors.pop()
                const authorList = authors === 0 ? `${lastAuthor.firstName} ${lastAuthor.lastName}` : `${authors.map(a => `${a.firstName} ${a.lastName}`).join(', ')} and ${lastAuthor.firstName} ${lastAuthor.lastName}`
                return (
                  <Box paddingTop="20px">
                    <Link to={`/journals/biology/${articleId}`} paddingBottom="20px"><strong><Markup content={title} /></strong>{authorList}<br/> {doi}</Link>
              </Box>
                );
              })}
</>
          )}

          {!month && year && (
            <UnorderedList>
              {Object.entries(volumes[year]).map(([issue, articles]) => {
                return (
                  <ListItem key={`issue-${issue}`}>
                    <Link to={`/archives/${year}/${issue}`}>Issue {issue}</Link>{" "}
                    ({articles.length} microPublications)
                  </ListItem>
                );
              })}
            </UnorderedList>
          )}

          {!year &&
            Object.entries(volumes).map(([volume, issues]) => {
              return (
                <UnorderedList key={`volume-${volume}`}>
                  <ListItem>
                    <Link to={`/archives/${volume}`}>Volume {volume}</Link>
                  </ListItem>
                </UnorderedList>
              );
            })}
        </Container>
    </Layout>
  );
};

export default Archives;
